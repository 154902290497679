<template>
  <section>
    <b-button
      type="is-text"
      size="is-small"
      @click="isComponentModalActive = true"
      ><span class="has-icon"><i class="fas fa-cog"></i></span
    ></b-button>

    <b-modal
      :active.sync="isComponentModalActive"
      has-modal-card
      :can-cancel="false"
    >
      <location-setting-view :location-id="locationId"></location-setting-view>
    </b-modal>
  </section>
</template>

<script>
import LocationSettingView from '@/components/locations/settings/LocationSetttingView'

export default {
  components: {
    LocationSettingView
  },
  props: {
    locationId: String
  },
  data() {
    return {
      isComponentModalActive: false
    }
  }
}
</script>

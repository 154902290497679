<template>
  <div>
    <div class="columns is-centered">
      <div class="column ">
        <h1 id="v-step-0" class="title has-text-centered">
          Cadastro de Estabelecimentos
        </h1>
      </div>
    </div>
    <div class="columns">
      <div class="column is-10 is-offset-1  v-step-1">
        <add-location
          v-if="networkOnLine"
          :locations-products="locations"
        ></add-location>
      </div>
    </div>
    <div class="columns">
      <div class="column is-10 is-offset-1">
        <location-list class="product-list v-step-2"></location-list>
      </div>
    </div>
    <v-tour name="myTour" :steps="steps" :callbacks="myCallbacks"></v-tour>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AddLocation from '@/components/locations/AddLocation'
import LocationList from '@/components/locations/LocationList'
import locationSteps from '@/misc/tours/location-steps'

export default {
  components: { AddLocation, LocationList },
  data() {
    return {
      steps: locationSteps,
      myCallbacks: {
        onStop() {
          localStorage.setItem('addLocationTour', true)
        }
      }
    }
  },
  computed: {
    ...mapState('app', ['networkOnLine']),
    ...mapState('locations', ['locations'])
  },
  mounted() {
    if (localStorage.getItem('addLocationTour') === null) {
      this.$tours.myTour.start()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>

<template>
  <div style="border-bottom: 1px #696969">
    <div class="columns is-mobile">
      <div class="column is-1">
        🏢
      </div>

      <div class="column ">
        {{ data.name }} /
        <b-tooltip :label="`${data.surveysTaken || 0} avaliações`">
          <i class="fas fa-star has-text-warning	"></i>
          {{
            (data.ratingSum &&
              data.surveysTaken &&
              (data.ratingSum / data.surveysTaken).toFixed(2)) ||
              '-'
          }}
        </b-tooltip>
      </div>

      <div class=" column 	has-text-grey-lighter">
        {{ data.kindOfLocation }}
      </div>
      <div class=" column 	has-text-grey-lighter has-text-right">
        <location-settings :location-id="data.id"> </location-settings>
      </div>
    </div>

    <nav class="level">
      <div class="level-item has-text-centered">
        <div>
          <span class="tag">Capacidade</span>
          <span class="tag is-primary">{{ Number(data.maxQtd) }}</span>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <span class="tag  v-step-6">Fila Atual</span>
          <span class="tag is-primary">{{ Number(data.actualQty) || 0 }}</span>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <div class="tags has-addons">
            <span class="tag">Status</span>
            <span
              v-show="Number(data.actualQty) / Number(data.maxQtd) < 0.5"
              class="tag is-primary"
            >
              Normal</span
            >
            <span
              v-if="
                Number(data.actualQty) / Number(data.maxQtd) >= 0.5 &&
                  Number(data.actualQty) / Number(data.maxQtd) <= 0.9
              "
              class="tag is-warning"
            >
              Atenção</span
            >
          </div>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div class="v-step-4">
          <router-link
            :to="{ name: 'open', params: { id: data.id, name: data.name } }"
            class="button is-small is-info"
          >
            <span class="panel-icon has-text-white">
              <i class="fas fa-qrcode is-info" aria-hidden="true"></i>
            </span>
            <span>
              Gerenciar fila
            </span>
          </router-link>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div class="v-step-4">
          <router-link
            :to="{ name: 'utils', params: { locationId: data.id } }"
            class="button is-small is-info"
          >
            <span class="panel-icon has-text-white">
              <i class="fas fa-calculator"></i>
            </span>
            <span>
              Contador pessoas
            </span>
          </router-link>
        </div>
      </div>
      <div class="level-item has-text-centered v-step-5">
        <div>
          <router-link
            class="button is-small is-info is-outlined"
            :to="{ name: 'location', params: { id: data.id } }"
          >
            <span class="panel-icon has-text-info">
              <i class="fas fa-print"></i>
            </span>
            <span>
              Ver/imprimir QRCode
            </span>
            QRCode
          </router-link>
        </div>
      </div>
      <div class="level-item has-text-centered v-step-7">
        <div
          v-if="!disableActions"
          class="button is-danger is-outlined is-small"
          @click="$emit('deleteProduct', data.id)"
        >
          <span class="panel-icon has-text-danger">
            <i class="fas fa-eraser " aria-hidden="true"></i>
          </span>
          <span>
            {{ isProductDeletionPending ? 'Deletando...' : 'Deletar' }}
          </span>
        </div>
      </div>
    </nav>
    <hr />
  </div>
</template>

<script>
import LocationSettings from '@/components/locations/settings/LocationSettings'

export default {
  components: { LocationSettings },
  props: {
    data: Object,
    isProductDeletionPending: Boolean,
    disableActions: Boolean
  }
}
</script>

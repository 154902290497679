<template>
  <div>
    <nav class="panel is-primary">
      <p class="panel-heading ">
        Estabelecimentos
      </p>
      <!-- <div class="panel-block">
        <p class="control has-icons-left">
          <input class="input" type="text" placeholder="Search" />
          <span class="icon is-left">
            <i class="fas fa-search" aria-hidden="true"></i>
          </span>
        </p>
      </div> -->
      <p class="panel-tabs is-info">
        <a class="is-active"
          >Todos ({{ (products && products.length) || 0 }})</a
        >
      </p>
      <div v-show="products !== undefined">
        <div class="columns ">
          <div class="column  v-step-3">
            <location-item
              v-for="(product, index) in products"
              :key="product.id"
              class="column is-full"
              :index="index"
              :is-product-deletion-pending="
                isProductDeletionPending(product.id)
              "
              :disable-actions="!networkOnLine"
              :data="product"
              @deleteProduct="deleteUserProduct"
            ></location-item>
          </div>
        </div>
      </div>
      <div v-show="products == undefined || products.length === 0">
        <a class="panel-block">
          <span class="panel-icon">
            <i class="far fa-building"></i>
          </span>
          <span class="has-text-centered">
            Nenhum estabelecimento cadastrado ainda, cadastre o primeiro.
          </span>
        </a>
      </div>
      <!-- <div v-show="products.length <= 0">
        <a class="panel-block">
          <span class="panel-icon">
            <i class="far fa-user" aria-hidden="true"></i>
          </span>
          Nenhum estabelecimentos adicionado até agora
        </a>
      </div> -->
    </nav>
    <v-tour
      name="addLocationTour"
      :steps="steps"
      :callbacks="myCallbacks"
    ></v-tour>
  </div>
</template>

<script>
import LocationItem from '@/components/locations/LocationItem'
import addLocationsSteps from '@/misc/tours/add-location-steps'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  components: { LocationItem },
  props: {
    locationsProducts: null
  },
  data() {
    return {
      steps: addLocationsSteps,
      myCallbacks: {
        onStop() {
          localStorage.setItem('listLocationTour', true)
        }
      },
      keyValue: false
    }
  },

  computed: {
    ...mapGetters('locations', ['isProductDeletionPending']),
    ...mapState('locations', ['products']),
    ...mapState('app', ['networkOnLine'])
  },
  created() {
    this.$store.dispatch('locations/getUserProducts')
  },
  methods: {
    ...mapActions('locations', ['deleteUserProduct', 'getUserProducts'])
  }
}
</script>

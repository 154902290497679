export const addLocationSteps = [
  {
    target: '.v-step-3', // We're using document.querySelector() under the hood
    header: {
      title: 'Lista de estabelecimentos'
    },
    content:
      'Estes são os estabelcimentos que você cadastrou. Confira o status e realize ações com as filas.'
  },
  {
    target: '.v-step-6', // We're using document.querySelector() under the hood
    header: {
      title: 'Status da sua fila'
    },
    content:
      'Capacidade máxima de atendiemnto diário, número de pessoas na fila no momento e o status (se a fila está próxima da capacidade máxima ou não)'
  },
  {
    target: '.v-step-4', // We're using document.querySelector() under the hood
    header: {
      title: 'Gerenciar fila'
    },
    content:
      'Aqui você administra sua fila. Adiciona clientes, envia notificações, inicia e finaliza atendimentos.'
  },
  {
    target: '.v-step-5', // We're using document.querySelector() under the hood
    header: {
      title: 'Ver ou imprimir QRCode'
    },
    content:
      'Crie um cartaz para que seus clientes possam acessar a fila com a partir do QRCode. Você pode imprimí-lo ou deixar aberto em uma tela.'
  },
  {
    target: '.v-step-7', // We're using document.querySelector() under the hood
    header: {
      title: 'Deletar o estabelecimento'
    },
    content:
      'Está opção apaga o estabelecimento e deleta sua fila e deus permanentemente'
  }
]

export default addLocationSteps

<template>
  <div>
    <section>
      <div class="columns">
        <div class="column ">
          <b-field label="Nome do estabelecimento">
            <input
              class="input"
              type="text"
              :value="productNameToCreate"
              @input="setProductNameToCreate($event.target.value)"
            />
          </b-field>
        </div>
        <div class="column ">
          <b-field label="Estabelecimento"> </b-field>

          <div class="select is-fullwidth">
            <select
              v-model="kindOfLocation"
              @input="
                setKindOfLocation($event.target.value)
                commitKindOfLocation($event.target.value)
              "
            >
              <option selected>Escolha</option>
              <option>Posto de saúde</option>
              <option>Supermercado</option>
              <option>Clínica</option>
              <option>Banco</option>
              <option>Cartório</option>
              <option>Outro</option>
            </select>
          </div>
        </div>
        <div class="column ">
          <b-field label="Capacidade pessoas">
            <input
              placeholder="Máximo pessoas"
              class="input"
              type="number"
              min="0"
              :value="locationMaxQty"
              @input="setMaximumCapacity($event.target.value)"
            />
          </b-field>
        </div>

        <div class="column ">
          <b-field label="Confirmar?">
            <b-button
              :disabled="
                productNameToCreate.length < 3 ||
                  kindOfLocation === 'Escolha' ||
                  locationMaxQty === ''
              "
              :class="{ disabled: productCreationPending }"
              class="button is-primary  is-fullwidth"
              type="is-primary"
              @click="confirm()"
              >Adicionar estabelecimento</b-button
            >
          </b-field>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'

export default {
  computed: mapState('locations', [
    'productNameToCreate',
    'productCreationPending',
    'locationMaxQty',
    'kindOfLocation'
  ]),
  methods: {
    ...mapMutations('locations', [
      'setProductNameToCreate',
      'setMaximumCapacity',
      'setKindOfLocation'
    ]),
    ...mapActions('locations', [
      'triggerAddProductAction',
      'commitKindOfLocation'
    ]),
    confirm() {
      this.$buefy.dialog.confirm({
        message: 'Confirma adicionar um novo estabelecimento?',
        onConfirm: () =>
          this.$store.dispatch('locations/triggerAddProductAction').then(() => {
            this.$buefy.toast.open('Estabelecimento adicionado')
          })
      })
    }
  }
}
</script>

export const locationSteps = [
  {
    target: '#v-step-0', // We're using document.querySelector() under the hood
    header: {
      title: 'Passo 1'
    },
    content: 'Vamos cadastrar um estabelecimento para que possa abrir filas.'
  },
  {
    target: '.v-step-1',
    header: {
      title: 'Dados do estabelecimento'
    },
    content:
      'Preencha os dados do estabelecimento e clique em "Adicionar". Aperte em "Próximo" quando adicionar.',
    params: {
      highlight: true
    }
  },
  {
    target: '.v-step-2',
    content:
      'Agora que você criou sua lista, vamos você pode "Gerenciar sua fila"',
    params: {
      placement: 'top' // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
    }
  }
]

export default locationSteps

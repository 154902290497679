<template>
  <section>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Ajustes para {{ updatingParams.name }}</p>
      </header>
      <section class="modal-card-body">
        <b-tabs v-model="activeTab">
          <b-tab-item label="Geral">
            <div class="field">
              <b-field label="Nome do estabelecimento">
                <input
                  v-model="updatingParams.name"
                  class="input"
                  type="text"
                  @input="updateLocationName($event.target.value)"
                />
              </b-field>
            </div>
            <div class="field">
              <b-field label="Capacidade máxima">
                <input
                  v-model="updatingParams.maxQtd"
                  class="input"
                  type="number"
                  min="0"
                  @input="updateMaxQtd($event.target.value)"
                />
              </b-field>
            </div>
            <div class="field">
              <b-field
                label="Ativar a diminuição temporizada de pessoas do 'Contador de pessoas?'"
              >
                <input
                  v-model="updatingParams.params.useAutoDecrement"
                  class="checkbox"
                  type="checkbox"
                  @change="useAutoDecrement($event.target.checked)"
                />
              </b-field>
            </div>
            <div v-show="updatingParams.params.useAutoDecrement" class="field">
              <b-field
                label="Em quanto tempo a a diminuição temporizada deve acontecer?"
              >
                <input
                  v-model="updatingParams.params.timeForAutoDecrement"
                  class="input"
                  type="number"
                  min="5"
                  @input="setTimerForAutoDecrement($event.target.value)"
                />
              </b-field>
            </div>
          </b-tab-item>
        </b-tabs>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-primary" type="button" @click="saveSettings()">
          Salvar
        </button>
        <button class="button " type="button" @click="$parent.close()">
          Cancelar
        </button>
      </footer>
    </div>
  </section>
</template>

<script>
import firebase from 'firebase/app'
import { mapMutations, mapState } from 'vuex'

export default {
  props: {
    locationId: String
  },
  computed: {
    ...mapState('locations', ['updatingParams'])
  },
  beforeMount() {
    this.getLocationSettings()
  },
  methods: {
    ...mapMutations('locations', ['setUpdatingParams']),
    updateMaxQtd(value) {
      this.updatingParams.maxQtd = value
      this.$store.commit('locations/setUpdatingParams', this.updatingParams)
    },
    async getLocationSettings() {
      await firebase
        .firestore()
        .collection('locations')
        .doc(this.locationId)
        .get()
        .then(snap => {
          console.log(snap.data())
          this.$store.commit('locations/setUpdatingParams', snap.data())
        })
    },
    updateLocationName(value) {
      this.updatingParams.name = value

      this.$store.commit('locations/setUpdatingParams', this.updatingParams)
    },
    useAutoDecrement(value) {
      this.updatingParams.params.useAutoDecrement = value

      this.$store.commit('locations/setUpdatingParams', this.updatingParams)
    },
    setTimerForAutoDecrement(value) {
      this.updatingParams.params.timeForAutoDecrement = value

      this.$store.commit('locations/setUpdatingParams', this.updatingParams)
    },
    saveSettings() {
      firebase
        .firestore()
        .collection('locations')
        .doc(this.locationId)
        .update(this.updatingParams)
        .then(() => {
          this.$store.dispatch('locations/getUserProducts', this.locationId)
          this.$parent.close()
          this.$buefy.toast.open('Parâmetros atualiados')
        })
    }
  }
}
</script>
